import { h } from "preact";

import "./navbar-styles.scss";

export function NavBar({ actived }) {
  const dataLayer = window.dataLayer || [];

    return (
        <nav class="navbar navbar-dark navbar-expand-lg fixed-top">
    <div class="container-fluid">
      <a class="navbar-brand" href="/"></a>
      <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarNav" aria-controls="navbarNav" aria-expanded="false" aria-label="Toggle navigation">
        <span class="navbar-toggler-icon"></span>
      </button>
      <div class="collapse navbar-collapse" id="navbarNav">
        <ul class="navbar-nav">
        <li class="nav-item">
            <a class="nav-link" data-active={actived === "index"} href="/index.html" onClick={dataLayer.push({'event': 'start_home', 'customer_id': false, 'page_origin':  actived })}>Início</a>
          </li>
          <li class="nav-item">
            <a class="nav-link" data-active={actived === "chefspay"} href="/chefspay.html" onClick={dataLayer.push({'event': 'start_chefspay', 'customer_id': false, 'page_origin':  actived })}>ChefsPay</a>
          </li>
          <li class="nav-item">
            <a class="nav-link" data-active={actived === "menu"} href="/cardapio.html" onClick={dataLayer.push({'event': 'start_digital_menu', 'customer_id': false, 'page_origin':  actived })}>Cardápio digital</a>
          </li>
          {/* <li class="nav-item">
            <a class="nav-link" data-active={actived === "restaurantLead"} href="javascript:;">Cadastre seu Restaurante</a>
          </li> */}
        </ul>
      </div>
      <a href="https://www.chefsclub.com.br/entrar" target="_blank" class="action-link" data-mobile="hide" onClick={dataLayer.push({'event': 'start_autentication', 'customer_id': false, 'page_origin':  actived })}>Cadastre-se ou faça login</a>
    </div>
  </nav>
    );
}