import { h } from "preact";

import "./footer-styles.scss";

export function Footer({ actived }) {
    const dataLayer = window.dataLayer || [];
    
    return (
        <footer className="footer">
            <div className="container">
                <div className="row">
                <div className="footer--col">
                    <h4 className="footer--links__title">Sobre o clube</h4>
                    <ul className="footer--links__list">
                    <li className="footer--links__item"><a href="/planos" data-active={actived === "plans"} onClick={dataLayer.push({'event': 'footer_pricing', 'customer_id': false, 'page_origin': actived })}>Planos</a></li>
                    <li className="footer--links__item"><a href="https://blog.chefsclub.com.br/" target="_blank">ChefsBlog</a></li>
                    <li className="footer--links__item"><a href="/index.html#download" onClick={dataLayer.push({'event': 'footer_start_downloadApp', 'customer_id': false, 'page_origin': actived })}>Baixe nosso app</a></li>
                    </ul>
                </div>
                <div className="footer--col footer--col__auto">
                    <h4 className="footer--links__title">Contato</h4>
                    <ul className="footer--links__list">
                    <li className="footer--links__item"><a href="https://atendimento.chefsclub.com.br/hc/pt-br" onClick={dataLayer.push({'event': 'open_sac', 'customer_id': false, 'page_origin': actived })}>Central de ajuda</a></li>                
                    <li className="footer--links__item"><a href="mailto:meajuda@chefsclub.com.br" onClick={dataLayer.push({'event': 'send_email', 'customer_id': false, 'page_origin': actived })}>Envie-nos um e-mail</a></li>                
                    <li className="footer--links__item"><span><strong>4003-6177</strong> (Capitais e regiões metropolitanas)</span></li>                
                    <li className="footer--links__item"><span><strong>(21) 2018-0077</strong> (Demais localidades)</span></li>                
                    </ul>
                </div>
                <div className="footer--col">
                    <h4 className="footer--social__title">Siga o ChefsClub</h4>
                    <ul className="footer--social__list">
                    <li className="footer--social__item"><a href="https://www.google.com/url?q=https://www.facebook.com/ChefsClub.com.br" className="facebook" onClick={dataLayer.push({'event': 'open_facebook', 'customer_id': false, 'page_origin': actived })}>Facebook</a></li>
                    <li className="footer--social__item"><a href="https://www.google.com/url?q=https://instagram.com/chefsclub" className="instagram" onClick={dataLayer.push({'event': 'open_instagram', 'customer_id': false, 'page_origin': actived })}>Instagram</a></li>
                    <li className="footer--social__item"><a href="https://www.google.com/url?q=https://twitter.com/chefsclub" className="twitter" onClick={dataLayer.push({'event': 'open_twitter', 'customer_id': false, 'page_origin': actived })}>Twitter</a></li>
                    </ul>
                </div>
                </div>
                <div className="row footer--copyright">
                <span>© {new Date().getFullYear()} Chefs Club S/A.<br data-desktop="hide" /> Todos os direitos reservados.</span>
                <span><a href="https://www.chefsclub.com.br/termos-e-condicoes" target="_blank" onClick={dataLayer.push({'event': 'open_termsOfService', 'customer_id': false, 'page_origin': actived })}>Termos e Condições</a> | <a href="https://www.chefsclub.com.br/politica-de-privacidade" target="_blank" onClick={dataLayer.push({'event': 'open_privacyPolicy', 'customer_id': false, 'page_origin': actived })}>Política de Privacidade</a></span>
                </div>
            </div>
        </footer>
    );
}