import Rellax from "./libraries/rellax";
import { isDesktop } from "./utilities";

export function loadRellax() {
    if (isDesktop()) {
        if (!window.rellax) {
          window.rellax = new Rellax('.rellax', {});
        }
      } else if (window.rellax) {
        window.rellax.destroy();
      }    
}