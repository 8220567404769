import { h } from "preact";
import { useCallback, useState } from "preact/hooks";

import "./cookie-banner-styles.scss";

const target = 'acceptChefsClubCookies';

export function CookieBannerApp() {
    const [ accepted, setAccepted ] = useState(+window.localStorage.getItem(target));
    const [className, setClassName] = useState(accepted ? 'cookiebanner hide' : 'cookiebanner');

    const handleClick = useCallback(() => {
        window.localStorage.setItem(target, 1);
        setAccepted(1);
        setClassName(`${className} hide`);
        // TODO send event to GA
    }, []);


    return (
        <div class={className} id="js-cookiebanner">
            <p>O ChefsClub usa cookies para analisar o tráfego deste site. As informações sobre seu uso do site são compartilhadas com o ChefsClub com essa finalidade. Ver detalhes.</p>
            <button onClick={handleClick}>OK</button>
        </div>
    )
}